import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Field,
  Textarea,
  Input,
} from "@fluentui/react-components";
import axios from "axios";
import { ToastContext } from "../Contexts/ToastContext";

export const NewPrompt = ({ fetchAppDetails, appId }) => {
  const [promptName, setPromptName] = useState("");
  const [defaultGptPrompt, setDefaultGptPrompt] = useState("");
  const [promptNameError, setPromptNameError] = useState(false);
  const [defaultGptPromptError, setDefaultGptPromptError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { showToast } = useContext(ToastContext);

  const handlePromptNameChange = (event) => {
    setPromptName(event.target.value);
    setPromptNameError(false);
  };

  const handlePromptValChange = (event) => {
    setDefaultGptPrompt(event.target.value);
    setDefaultGptPromptError(false);
  };

  const handleCreate = async () => {
    if (promptName.length === 0) {
      setPromptNameError(true);
    }
    if (defaultGptPrompt.length === 0) {
      setDefaultGptPromptError(true);
    }
    try {
      // Update this line to send the request to the new route
      await axios.post(`/publicApi/prompts/${appId}/newPrompt`, {
        name: promptName,
        defaultGptPrompt: defaultGptPrompt,
      });
      setIsDialogOpen(false);
      showToast("Added new prompt!");
      setPromptName("");
      setDefaultGptPrompt("");
      fetchAppDetails();
    } catch (error) {
      console.error("Error creating prompt for app:", error);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(event, data) => {
        setIsDialogOpen(data.open);
      }}
    >
      <DialogTrigger disableButtonEnhancement>
        <Button onClick={() => setIsDialogOpen(true)}>New Prompt</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Create a new prompt</DialogTitle>
          <DialogContent>
            <Field size="large" label="Prompt Name">
              <Input value={promptName} onChange={handlePromptNameChange} />
            </Field>
            {promptNameError && (
              <div className="error-message">
                Please enter a valid label for the prompt.
              </div>
            )}
            <div className="field-gap" />
            <Field size="large" label="System Prompt">
              <Textarea
                value={defaultGptPrompt}
                onChange={handlePromptValChange}
              />
            </Field>
            {defaultGptPromptError && (
              <div className="error-message">
                Please enter a valid system prompt for GPT.
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={handleCreate}>
              Create
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
