import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "bulma/css/bulma.min.css";

const rootElement = document.getElementById("root");
createRoot(rootElement).render(<App />);

registerServiceWorker();
