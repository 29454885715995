import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AllApps = ({ apps }) => {
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    console.log(id);
    navigate(`/app/${id}`);
  };

  return (
    <div>
      {apps.length > 0 && (
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>App Name</th>
              <th>Use Case</th>
            </tr>
          </thead>
          <tbody>
            {apps.map((item, index) => (
              <tr
                key={index}
                className="app-table-row"
                onClick={() => handleRowClick(item.id)}
              >
                <td className="app-table-item">{item.appName}</td>
                <td className="app-table-item">{item.useCase}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AllApps;
