import React, { useState, useEffect } from "react";
import { NewApp } from "./NewUseCase";
import AllApps from "./AllApps";
import axios from "axios";
import { ArrowExitFilled } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [apps, setApps] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getApps();
  }, []);

  async function getApps() {
    const response = await axios.get(`/publicApi/prompts`);
    setApps(response.data);
  }
  return (
    <>
      <div className="header-row">
        <div className="header-group">
          <h1 className="large-title">Flubert Control Center</h1>
          <h1 className="header-subtitle">
            <b>Caution:</b> Changes here will affect all related Flubert front
            ends.
          </h1>
        </div>
        <div>
          <div className="log-out">
            <Button
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
              icon={<ArrowExitFilled />}
            >
              Log out
            </Button>
          </div>
        </div>
        <NewApp getApps={getApps} />
      </div>
      <AllApps apps={apps} />
    </>
  );
};

export default Home;
