import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Card, Textarea } from "@fluentui/react-components";
import { NewPrompt } from "./NewPrompt";
import { ToastContext } from "../Contexts/ToastContext";
import { Delete28Regular } from "@fluentui/react-icons";

const AppDetails = () => {
  const { id: appId } = useParams();
  const [appDetails, setAppDetails] = useState(null);
  const { showToast } = useContext(ToastContext);

  async function fetchAppDetails() {
    try {
      const response = await axios.get(`/publicApi/prompts/${appId}`);
      setAppDetails(response.data);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  }

  useEffect(() => {
    fetchAppDetails();
  }, [appId]);

  const onAppDetailChange = (promptKey, fewShotIndex, newValue) => {
    setAppDetails((prevAppDetails) => {
      const updatedAppDetails = { ...prevAppDetails };
      updatedAppDetails.allPrompts[promptKey].fewShot[fewShotIndex].content =
        newValue;
      return updatedAppDetails;
    });
  };

  const saveAppDetails = async () => {
    try {
      await axios.put(`/publicApi/prompts/${appId}`, appDetails);
      showToast("Updated prompts!");
    } catch (error) {
      console.error("Error updating app details:", error);
    }
  };

  const setActivePrompt = (promptKey) => {
    setAppDetails((prevAppDetails) => {
      const updatedAppDetails = { ...prevAppDetails };
      updatedAppDetails.activePrompt = promptKey;
      return updatedAppDetails;
    });
  };

  // deletes few shot pair
  const deleteFewShot = (promptKey, fewShotIndex) => {
    setAppDetails((prevAppDetails) => {
      const updatedAppDetails = { ...prevAppDetails };
      updatedAppDetails.allPrompts[promptKey].fewShot.splice(fewShotIndex, 2);
      return updatedAppDetails;
    });
  };

  const addFewShot = (promptKey) => {
    setAppDetails((prevAppDetails) => {
      const updatedAppDetails = { ...prevAppDetails };
      updatedAppDetails.allPrompts[promptKey].fewShot.push({ content: "" });
      updatedAppDetails.allPrompts[promptKey].fewShot.push({ content: "" });
      return updatedAppDetails;
    });
  };

  console.log(appDetails);

  const renderPromptCards = () => {
    return Object.entries(appDetails.allPrompts).map(([key, prompt]) => (
      <Card key={key} className={`prompt-card`}>
        <div className="prompt-header-group">
          <h1 className="prompt-card-header">
            {prompt.name}
            {appDetails.activePrompt === key && (
              <div className="active-prompt-pill">Active</div>
            )}
          </h1>
          <div className="button-group">
            <Button onClick={() => addFewShot(key)}>Add Few Shot</Button>
            {appDetails.activePrompt !== key && (
              <Button onClick={() => setActivePrompt(key)}>Set Active</Button>
            )}
          </div>
        </div>
        {prompt.fewShot.map((val, index) => {
          if (index === 0) {
            return (
              <div
                className="prompt-section"
                key={index + prompt.name + "prompt-section"}
              >
                <p>System prompt:</p>

                <Textarea
                  className="large-textarea"
                  value={val.content}
                  onChange={(ev, newValue) =>
                    onAppDetailChange(key, index, newValue.value)
                  }
                  size="large"
                />
              </div>
            );
          } else if (index % 2 === 1) {
            return (
              <div
                key={index + prompt.name + "group"}
                className="few-shot-group"
              >
                <div className="few-shot-group-header">
                  <h2 className="few-shot-group-title">
                    Few Shot {Math.floor(index / 2) + 1}
                  </h2>
                  <Delete28Regular
                    onClick={() => deleteFewShot(key, index)}
                    className="icon-style"
                  />
                </div>
                <div className="prompt-section">
                  <p>User prompt:</p>

                  <Textarea
                    className="large-textarea"
                    value={val.content}
                    onChange={(ev, newValue) =>
                      onAppDetailChange(key, index, newValue.value)
                    }
                    size="large"
                    resize="vertical"
                  />
                </div>
                {index + 1 < prompt.fewShot.length && (
                  <div className="prompt-section">
                    <p>AI prompt:</p>

                    <Textarea
                      className="large-textarea"
                      value={prompt.fewShot[index + 1].content}
                      onChange={(ev, newValue) =>
                        onAppDetailChange(key, index + 1, newValue.value)
                      }
                      size="large"
                      resize="vertical"
                    />
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </Card>
    ));
  };

  return (
    <>
      {appDetails && (
        <>
          <div className="app-details-header">
            <div className="header-group">
              <h1 className="large-title">{appDetails.appName}</h1>
              <h1 className="header-subtitle">
                <b>Use Case:</b> {appDetails.useCase}
              </h1>
              <h1 className="header-subtitle">
                <b>Dev ID:</b> {appDetails.id}
              </h1>
            </div>
            <div className="button-group">
              <NewPrompt fetchAppDetails={fetchAppDetails} appId={appId} />
              <Button onClick={saveAppDetails}>Save</Button>
            </div>
          </div>
          <div className="cards-container">{renderPromptCards()}</div>
        </>
      )}
    </>
  );
};

export default AppDetails;
