import React from "react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import Home from "./Components/Home";
import AppDetails from "./Components/AppDetails";
import Login from "./Auth/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastProvider } from "./Contexts/ToastContext";
import { AuthProvider } from "./Contexts/AuthContext";
const App = () => {
  return (
    <Router>
      <FluentProvider theme={webLightTheme}>
        <ToastProvider>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/app/:id" element={<AppDetails />} />
            </Routes>
          </AuthProvider>
        </ToastProvider>
      </FluentProvider>
    </Router>
  );
};

export default App;
