import React, { createContext, useCallback } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const showToast = useCallback((message, options = {}) => {
    toast.success(message, { position: "top-right", ...options });
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer theme="light" hideProgressBar={true} transition={Slide} />
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
