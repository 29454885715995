import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Field,
  Textarea,
  Input,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import axios from "axios";

import { CheckmarkCircle48Regular } from "@fluentui/react-icons";

const appList = ["Flubert Design Assistant", "Flubert Doc Generator"];

export const NewApp = ({ getApps }) => {
  const [useCase, setUseCase] = React.useState("");
  const [defaultGptPrompt, setDefaultGptPrompt] = React.useState("");
  const [useCaseError, setUseCaseError] = React.useState(false);
  const [defaultGptPromptError, setDefaultGptPromptError] =
    React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [selectedApp, setSelectedApp] = React.useState(null);
  const [selectedAppError, setSelectedAppError] = React.useState(false);

  const handleUseCaseChange = (event) => {
    setUseCase(event.target.value);
    setUseCaseError(false);
  };

  const handleDefaultGptPromptChange = (event) => {
    setDefaultGptPrompt(event.target.value);
    setDefaultGptPromptError(false);
  };

  const handleAppSelection = (event, data) => {
    setSelectedApp(data.optionText);
    setSelectedAppError(false);
  };

  const handleCreate = async () => {
    if (useCase.length === 0) {
      setUseCaseError(true);
      return;
    }
    if (defaultGptPrompt.length === 0) {
      setDefaultGptPromptError(true);
      return;
    }
    if (!selectedApp) {
      setSelectedAppError(true);
      return;
    }
    try {
      const response = await axios.post(`/publicApi/newApp`, {
        appName: selectedApp,
        useCase: useCase,
        defaultPrompt: defaultGptPrompt,
      });
      setUseCase("");
      setDefaultGptPrompt("");
      setSelectedApp(null);
      setShowSuccessMessage(true);
      getApps();
    } catch (error) {
      console.error("Error creating prompt for app:", error);
    }
  };

  return (
    <Dialog
      onOpenChange={(event, data) => {
        if (!data.open) {
          console.log("closed modal");
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 1000);
        }
      }}
    >
      <DialogTrigger disableButtonEnhancement>
        <Button>New Use Case</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          {!showSuccessMessage && (
            <DialogTitle>Create a new use case</DialogTitle>
          )}
          <DialogContent>
            {showSuccessMessage ? (
              <div className="success-modal">
                <CheckmarkCircle48Regular />
                <h1 className="success-title">App created successfully!</h1>
              </div>
            ) : (
              <>
                <Field size="large" label="Select App">
                  <Dropdown
                    selectedKey={selectedApp}
                    onOptionSelect={handleAppSelection}
                  >
                    {appList.map((app) => (
                      <Option key={app}>{app}</Option>
                    ))}
                  </Dropdown>
                </Field>
                {selectedAppError && (
                  <div className="error-message">
                    Please select an app from the dropdown.
                  </div>
                )}
                <div className="field-gap" />
                <Field size="large" label="Use Case">
                  <Input value={useCase} onChange={handleUseCaseChange} />
                </Field>
                {useCaseError && (
                  <div className="error-message">
                    Please enter a valid app name.
                  </div>
                )}
                <div className="field-gap" />
                <Field size="large" label="Default GPT Prompt">
                  <Textarea
                    value={defaultGptPrompt}
                    onChange={handleDefaultGptPromptChange}
                  />
                </Field>
                {defaultGptPromptError && (
                  <div className="error-message">
                    Please enter a valid default GPT prompt.
                  </div>
                )}
              </>
            )}
          </DialogContent>
          {showSuccessMessage ? (
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
            </DialogActions>
          ) : (
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
              <Button appearance="primary" onClick={handleCreate}>
                Create
              </Button>
            </DialogActions>
          )}
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
