import React from "react";
import { Button } from "@fluentui/react-components";
const Login = () => {
  const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
  const handleLogin = () => {
    const stateData = {
      reqOrigin: "webapp",
    };
    const stateParam = encodeURIComponent(JSON.stringify(stateData));
    const redirectUri = `${
      process.env.REACT_APP_ENV === "DEV"
        ? "http://localhost:3000" // server is at 3000, web app at 3001
        : "https://fluent-docs-ai-search.microsoft.design"
    }/auth/callback`;
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=query&scope=https://graph.microsoft.com/User.Read&state=${stateParam}`;
    window.location.href = authUrl;
  };

  return (
    <div>
      <Button onClick={handleLogin}>Login</Button>
    </div>
  );
};

export default Login;
